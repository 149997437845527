import { useEffect, useRef } from 'react';
import sheet from './Loaders.module.css';

function Loader1() {
    const element_ = useRef();
    useEffect(() => {
        element_.current.scrollIntoView({ behavior: "smooth" });
    }, [])
    return (
        <div ref={element_} className={sheet.loader1} id="waitingForResp">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}

export default Loader1