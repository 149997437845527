/* eslint-disable react-hooks/exhaustive-deps */
import { Environment, Loader, OrbitControls, OrthographicCamera, Stars, useAnimations, useFBX, useGLTF } from '@react-three/drei';
import { Canvas, useFrame } from '@react-three/fiber';
import axios from 'axios';
import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { AnimationMixer, LoopOnce } from 'three';
import avaStyle from './AvatarAva.module.css';
import blinkData from "./modelsAndRelated/blendDataBlink.json";
import createAnimation from "./modelsAndRelated/converter";
import idleFbFile from "./modelsAndRelated/idle.fbx";
import modelGlb from './modelsAndRelated/model.glb';
// import modelGlb from './modelsAndRelated/GLB_1.glb';
import Comp from './avatarUtlity/Comp';
import photo_studio_loft_hall_1k from './images/photo_studio_loft_hall_1k.hdr';
// import modelGlb from './modelsAndRelated/GLB-New/model.glb';
import sampleAnimation from './modelsAndRelated/sampleAnimation.json';

const host = 'http://65.111.164.242:4001/v1'; // Same server
// const host = 'localhost:4001/v1';
const audioHost = 'http://65.111.164.242:4001'; // Same server
// const audioHost = 'localhost:4001';

export default function Samantha() {
	const audioPlayer = useRef();
	const [speak, setSpeak] = useState(false);
	const [text, setText] = useState("Getting audio, but Lip-sync is not working.");
	const [audioSource, setAudioSource] = useState(null);
	const [playing, setPlaying] = useState(false);


	const queryParameters = new URLSearchParams(window.location.search);
	const textToSpeak = queryParameters.get('textToSpeak');
	const allowSpeak = queryParameters.get('allowSpeak');
	useEffect(() => {
		// console.log({ textToSpeak, allowSpeak, speakNowType: typeof allowSpeak, speakNowType_: typeof !!allowSpeak });
		if (!!allowSpeak === true) {
			setText(textToSpeak);
			setSpeak(!!allowSpeak)
		}
	}, [textToSpeak, allowSpeak]);

	// End of play
	function playerEnded(e) {
		setAudioSource(null);
		setSpeak(false);
		setPlaying(false);
	}

	// Player is read
	function playerReady(e) {
		audioPlayer.current.audioEl.current.play();
		setPlaying(true);
	}

	return (
		<>
			<Canvas dpr={2} onCreated={(ctx) => { ctx.gl.physicallyCorrectLights = true; }}>
				{/* Cursor Model view control */}
				<OrbitControls
					enableZoom={true}
					target={[0, 1.65, 0]} />
				<Stars />
				{/* Put these 2 line in Orbit controls so limit zoom */}
				{/* maxZoom={1700}  // Maximum zoom level (22000) 
							minZoom={50}  // Minimum zoom level (1000) 
					minAzimuthAngle={-Math.PI / 9}	// right
					maxAzimuthAngle={Math.PI / 9}	// left
					maxPolarAngle={Math.PI / 1.55}	// down
					minPolarAngle={Math.PI / 3}	// up
							*/}
				{/* Model view control */}
				<OrthographicCamera makeDefault zoom={400} position={[0, 1.65, 1.5]} />
				<Suspense fallback={null}>
					<Environment background={false} files={photo_studio_loft_hall_1k} />
				</Suspense>
				<Suspense fallback={null}>
					<Comp />
				</Suspense>
				<Suspense fallback={null}>
					<Avatar avatar_url={modelGlb} speak={speak} setSpeak={setSpeak} text={text} setAudioSource={setAudioSource} playing={playing} />
				</Suspense>
			</Canvas>
			<Loader dataInterpolation={(p) => `Loading... please wait`} />
			<ReactAudioPlayer src={audioSource} ref={audioPlayer} onEnded={playerEnded} onCanPlayThrough={playerReady} />
			<div className={avaStyle.speakText}>
				<textarea rows={4} type="text" className={avaStyle.text} value={text} onChange={(e) => setText(e.target.value.substring(0, 200))} />
				<button onClick={() => setSpeak(true)} className={avaStyle.speakButton}> {speak ? 'Running...' : 'Speak'}</button>
			</div>
		</>
	)
}

function Avatar({ avatar_url, speak, setSpeak, text, setAudioSource, playing }) {
	let gltf = useGLTF(avatar_url);
	let morphTargetDictionaryBody = null;
	let morphTargetDictionaryLowerTeeth = null;
	var allNodeTypes = [];
	var allNodeName = [];
	gltf.scene.traverse(node => {


		if (!allNodeTypes.includes(node.type))
			allNodeTypes.push(node.type);
		if (!allNodeName.includes(node.name))
			allNodeName.push(node.name);
		// if (node.name.indexOf("Object") > -1) console.warn(node.name, node.type, node, `visible ${node.visible}`);


		if (['Mesh', 'LineSegments', 'SkinnedMesh'].includes(node.type)) {
			node.castShadow = true;
			node.receiveShadow = true;
			node.frustumCulled = false;
			/*
			if (node.name.includes("Body")) {
				node.castShadow = true;
				node.receiveShadow = true;

				node.material = new MeshPhysicalMaterial();
				node.material.map = bodyTexture;
				// node.material.shininess = 60;
				node.material.roughness = 1.7;

				// node.material.specularMap = bodySpecularTexture;
				node.material.roughnessMap = bodyRoughnessTexture;
				node.material.normalMap = bodyNormalTexture;
				node.material.normalScale = new Vector2(0.6, 0.6);
				morphTargetDictionaryBody = node.morphTargetDictionary;
				node.material.envMapIntensity = 0.8;
				// node.material.visible = false;
			}

			if (node.name.includes("Eyes")) {
				node.material = new MeshStandardMaterial();
				node.material.map = eyesTexture;
				// node.material.shininess = 100;
				node.material.roughness = 0.1;
				node.material.envMapIntensity = 0.5;
			}

			if (node.name.includes("Brows")) {
				node.material = new LineBasicMaterial({ color: 0x000000 });
				node.material.linewidth = 1;
				node.material.opacity = 0.5;
				node.material.transparent = true;
				node.visible = false;
			}

			if (node.name.includes("Teeth")) {
				node.receiveShadow = true;
				node.castShadow = true;
				node.material = new MeshStandardMaterial();
				node.material.roughness = 0.1;
				node.material.map = teethTexture;
				node.material.normalMap = teethNormalTexture;

				node.material.envMapIntensity = 0.7;
			}

			if (node.name.includes("TeethLower")) {
				morphTargetDictionaryLowerTeeth = node.morphTargetDictionary;
			}

			if (node.name.includes("Hair")) {
				node.material = new MeshStandardMaterial();
				node.material.map = hairTexture;
				node.material.alphaMap = hairAlphaTexture;
				node.material.normalMap = hairNormalTexture;
				node.material.roughnessMap = hairRoughnessTexture;

				node.material.transparent = true;
				node.material.depthWrite = false;
				node.material.side = 2;
				node.material.color.setHex(0x000000);
				node.material.envMapIntensity = 0.3;
			}

			if (node.name.includes("TSHIRT")) {
				node.material = new MeshStandardMaterial();
				node.material.map = tshirtDiffuseTexture;
				node.material.roughnessMap = tshirtRoughnessTexture;
				node.material.normalMap = tshirtNormalTexture;
				node.material.color.setHex(0xffffff);
				node.material.envMapIntensity = 0.5;
			}
*/
		}
	});
	// console.log(" allNodeTypes, allNodeName", { allNodeTypes, allNodeName });
	const [clips, setClips] = useState([]);
	const mixer = useMemo(() => {
		return new AnimationMixer(gltf.scene)
	}, [gltf.scene]);

	useEffect(() => {
		let response = { data: sampleAnimation };
		// console.log("===", response);
		let { blendData, filename } = response.data;
		let newClips = [
			createAnimation(blendData, morphTargetDictionaryBody, 'HG_Body'),
			createAnimation(blendData, morphTargetDictionaryLowerTeeth, 'HG_TeethLower')
		];
		filename = audioHost + filename;
		setClips(newClips);
		setAudioSource(filename);
	}, [])


	useEffect(() => {
		// console.log(`speak changed to "${speak}," hit the api for text: "${text}"`);
		if (speak) {
			makeSpeech(text)
				.then(response => {
					// console.log({ Data: response.data, response, speak: "Speak this with animation" });
					if (response.status === 200) {
						// response = { data: sampleAnimation }; // Test
						let { blendData, filename } = response.data;
						let newClips = [
							createAnimation(blendData, morphTargetDictionaryBody, 'HG_Body'),
							createAnimation(blendData, morphTargetDictionaryLowerTeeth, 'HG_TeethLower')
						];
						filename = audioHost + filename;
						setClips(newClips);
						setAudioSource(filename);
					} else
						console.error(response.status, response.statusText);
				})
				.catch(err => {
					console.error(err);
					setSpeak(false);
				})
		}
	}, [speak]);

	let idleFbx = useFBX(idleFbFile);
	let { clips: idleClips } = useAnimations(idleFbx.animations);
	idleClips[0].tracks = idleClips[0].tracks.filter(track => track.name.includes("Head") || track.name.includes("Neck") || track.name.includes("Spine2"));
	console.error("=================", idleClips[0].tracks);
	idleClips[0].tracks = idleClips[0].tracks.map(track => {
		if (track.name.includes("Head"))
			track.name = "head.quaternion";
		if (track.name.includes("Neck"))
			track.name = "neck.quaternion";
		if (track.name.includes("Spine"))
			track.name = "spine2.quaternion";
		return track;
	});
	useEffect(() => {
		document.title = "Avatar Samantha";
		let idleClipAction = mixer.clipAction(idleClips[0]);
		if (idleClipAction)
			idleClipAction?.play();
		let blinkClip = createAnimation(blinkData, morphTargetDictionaryBody, 'HG_Body');
		let blinkAction = mixer.clipAction(blinkClip);
		if (blinkAction) {
			blinkAction?.play();
		} else {
			// alert("Unable to load")
		}

	}, []);

	// Play animation clips when available
	useEffect(() => {
		if (playing === false)
			return;
		clips.forEach(clip => {
			let clipAction = mixer.clipAction(clip);
			clipAction?.setLoop(LoopOnce);
			clipAction?.play();
		});
	}, [playing]);
	useFrame((state, delta) => {
		mixer.update(delta);
	});
	return <primitive object={gltf.scene} dispose={null} />;
}
function makeSpeech(text) {
	return axios.post(host + "/talk", { text });
}
