import { TbSend } from "react-icons/tb";
import { useSelector } from "react-redux";
import sSheet from "./QuestionExamples.module.css";
// import questionExamplesJSON from './QuestionExamplesJSON.json';

export default function QuestionExamples({ setTextBackRef }) {
    const companionChatObj = useSelector(allStoreReducers => allStoreReducers.companionChatSlice_Reducer);
    // companionChatObj.exampleQuestions = questionExamplesJSON;
    return (
        <div className={sSheet.entry}>
            <div className={sSheet.heading}>List of questions...</div>
            {companionChatObj.exampleQuestions.length < 1 ?
                <div className={sSheet.noQuestionFound}>No Examples given</div> :
                <div className={sSheet.listOfTopics}>
                    {companionChatObj.exampleQuestions.map((eachQGroup, index) => {
                        return <div key={`topic${index + 1}`} className={sSheet.eachGroup}>
                            <div className={sSheet.eachGroupHeading}>
                                {eachQGroup.heading || "No heading"}
                            </div>
                            <div className={sSheet.eachGroupQuestions}>
                                {!(eachQGroup.questions.length > 0) ?
                                    <div className={sSheet.noQuestionInTopic}>No questions on this topic</div> :
                                    eachQGroup.questions.map((eachQ, indexQ) => {
                                        return (
                                            <div key={`eachQ${indexQ}`} className={sSheet.eachQ}>
                                                <span>{eachQ || "---"}</span>
                                                <TbSend title="Send" className={sSheet.eachQIcon1} onClick={() => {
                                                    if (setTextBackRef.current) { // As this is an div
                                                        setTextBackRef.current.innerHTML = eachQ;
                                                        const range = document.createRange();
                                                        const sel = window.getSelection();
                                                        setTextBackRef.current.focus();
                                                        range.selectNodeContents(setTextBackRef.current);
                                                        range.collapse(false);
                                                        sel.removeAllRanges();
                                                        sel.addRange(range);
                                                    }

                                                }} />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    })}
                </div>
            }
        </div>
    )
}