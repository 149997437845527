export function getOrg(orgDmType) {
    let org, apiUrl, background;

    if (orgDmType === "aihumans.ai") {
        org = process.env.REACT_APP_aihumanOrg;
        apiUrl = `${process.env.REACT_APP_BACKEND}/web?orgId=${org}&respFormat=TEXT`;
        background = "HOMECARE";
    }  
    else if (orgDmType === "aiwizards.ai") {
        org = process.env.REACT_APP_aihumanOrg;
        apiUrl = `${process.env.REACT_APP_BACKEND}/web?orgId=${org}&respFormat=TEXT`;
        background = "TECH_OFFICE";
    } 
    else if (orgDmType === "dentalclinic.aihumans.ai") {//beautifulsmile
        org = process.env.REACT_APP_dc_beautifulsmiles;
        apiUrl = `${process.env.REACT_APP_BACKEND}/web?orgId=${org}&respFormat=TEXT`;
        background = "DENTAL_CLINIC";
    }
    else if (orgDmType === "affinityshospice.aihumans.ai") {//affinityhospice
        org = process.env.REACT_APP_hc_affinityhospice;
        apiUrl = `${process.env.REACT_APP_BACKEND}/web?orgId=${org}&respFormat=TEXT`;
        background = "TECH_OFFICE";
    } 
    else if (orgDmType === "aspire4home.aihumans.ai") {//aspire4home
        org = process.env.REACT_APP_hc_aspire4home;
        apiUrl = `${process.env.REACT_APP_BACKEND}/web?orgId=${org}&respFormat=TEXT`;
        background = "TECH_OFFICE";
    } 
    else if (orgDmType === "maple.aihumans.ai") {//maple
        org = process.env.REACT_APP_hc_maple;
        apiUrl = `${process.env.REACT_APP_BACKEND}/web?orgId=${org}&respFormat=TEXT`;
        background = "TECH_OFFICE";
    } else if (orgDmType === "bchnhealth.aihumans.ai") {//bchnhealth
        org = process.env.REACT_APP_hc_bchn;
        apiUrl = `${process.env.REACT_APP_BACKEND}/web?orgId=${org}&respFormat=TEXT`;
        background = "TECH_OFFICE";
    }
    else if(orgDmType === "bluetreedental.aihumans.ai"){// bluetreedental 
        org = process.env.REACT_APP_dc_bluetreedental;
        apiUrl = `${process.env.REACT_APP_BACKEND}/web?orgId=${org}&respFormat=TEXT`;
        background = "TECH_OFFICE";
    }
    else {
        if(window.location.hostname === "chat.aihumans.ai" || window.location.hostname === "dev.aihumans.ai" || window.location.hostname === "chat.aisommelier.org" || window.location.hostname === "localhost"){
            org = process.env.REACT_APP_dc_beautifulsmiles;
            apiUrl = `${process.env.REACT_APP_BACKEND}/web?orgId=${org}`;
            background = "DENTAL_CLINIC";
        }
    }

    return { org, apiUrl, background };
}
