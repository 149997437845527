import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Outlet, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import AvatarAva from './AvatarAva.jsx';
// import AvatarAvaCopy from './AvatarAvaCopy';
import Samantha from './Samantha';
import AvatarList from './avatarList/AvatarList.jsx';
import AppInstance from './health/AppInstance';
import { reduxStore } from './health/actions/reduxReducers/store';
import About from './health/globalPages/About';
import Contact from './health/globalPages/Contact';
import PageError from './health/globalPages/PageError';
import PageNotFound from './health/globalPages/PageNotFound';
import CompanionHome from './health/globalPages/home/CompanionHome';
import HealthCompanion from './health/healthCompanion/HealthCompanion';
import './index.css';

document.title = "Aihuman";
// http://localhost:3000
// https://dev.aihumans.ai

/* 
  Must call our site like this, best as token based
  To access person need your login with our health bot API,
  User will call our api to authenticate programmatically "http://localhost:8888/api/auth",
    if org + user + etc match, 
      server will divert them to companion page
  if(logged)
  Serve companion chat page
  else
  Serve companion home page with "Org/User not found" */

const routesWithJSX = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Outlet />} >
      <Route path="" element={<AvatarList />} />
      <Route path="testVesime" element={<h1>Test Vesime</h1>} />
      <Route path="avatars" element={<Outlet />}>
        {/* <Route path="" element={<AvatarList />} /> */}
        <Route path="eva" element={<AvatarAva />} />
        <Route path="samantha" element={<Samantha />} />
        {/* <Route path="demoHead" element={<AvatarAvaCopy />} /> */}
      </Route>
      <Route path="health" element={<AppInstance />}>
        <Route path="" element={<HealthCompanion />} />
        <Route path='home' element={<CompanionHome />} />
        <Route path="contact" element={<Contact />} />
        <Route path="about" element={<About />} />
        <Route path="error" element={<PageError />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Route>
  ))

createRoot(document.getElementById('root')).render(
  <StrictMode>

    <Provider store={reduxStore}>
      <RouterProvider router={routesWithJSX} />
    </Provider>
  </StrictMode>
);

