import { useEffect, useRef } from 'react';
import { typeOfChatMessage } from '../../actions/reduxReducers/CompanionChatSlice';
import { scrollIntoView } from '../../utilities/Utilities';
import Answer from './Answer';
import ErrorMessage from './ErrorMessage';
import sSheet from './Messages.module.css';
import Questions from './Questions';

export default function Messages({ chat, questionWritingArea, Lqt, chatIndex, lastIndex }) {
    const yourElementRef = useRef();
    useEffect(() => {
        scrollIntoView(yourElementRef);
    }, [chat])
    // console.log(chat);
    if (chat.type === typeOfChatMessage.QUESTION) {
        return (
            <div ref={yourElementRef} className={sSheet.askedQuestionCont}>
                <Questions chat={chat} questionWritingArea={questionWritingArea} />
            </div>
        )
    } else if (chat.type === typeOfChatMessage.ANSWER || chat.type === typeOfChatMessage.ANSWER_STREAMED) {
        return (
            <div ref={yourElementRef} className={sSheet.serverResponseCont}>
                <Answer chat={chat} Lqt={Lqt} chatIndex={chatIndex} lastIndex={lastIndex} />
            </div>
        )
    } else if (chat.type === typeOfChatMessage.API_ERROR) {
        return (
            <div ref={yourElementRef} className={sSheet.errorCont}>
                <ErrorMessage chat={chat} />
            </div>
        )
    } else return null;
}
