import ReactHtmlParser from 'html-react-parser';
import { useEffect } from 'react';
import { FaPause, FaPlay, FaStop } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useTts } from 'tts-react';
import { TOGGLE_COMPANION_PLAY } from '../../../actions/reduxReducers/RefCompSlice';
import sheet from './TextToSpeechComp.module.css';

export default function TextToSpeechComp(props) {
    // console.log(props);
    return (
        <CustomTTSComponent highlightText={props.highlightText} autoPlay={props.autoPlay_}>
            <p style={{ display: 'none' }} aria-hidden="true">{removeElementsNotToSpeak(props.elementAsString)}</p>
        </CustomTTSComponent>
    )
}

function CustomTTSComponent({ children, highlightText = false, autoPlay }) {
    const dispatch = useDispatch()

    const { ttsChildren, state, play, stop, pause } = useTts({
        children,
        markTextAsSpoken: highlightText,
        autoPlay
    });
    useEffect(() => {
        // Library first makes the isPlaying true then look for other to shut, making isPlaying false and stopping our video play
        let play = state.isPlaying && state.isReady;
        if (!play) {
            dispatch(TOGGLE_COMPANION_PLAY({ play }));
        } else {
            setTimeout(() => {
                dispatch(TOGGLE_COMPANION_PLAY({ play }));
            }, 100);
        }
        // eslint-disable-next-line
    }, [state.isPlaying, state.isReady])

    return (
        <div className={sheet.main}>
            {!state.isPlaying ?
                <FaPlay className={sheet.speakContent} onClick={play} /> :
                <>
                    <FaStop className={sheet.stopContent} onClick={stop} />
                    <FaPause title='Start from ' className={sheet.pauseContent} onClick={pause} />
                </>
            }
            {ttsChildren}
        </div>
    )
}


// If sending a node, send its innerHTML
function removeElementsNotToSpeak(stringToParse) {
    let stringToSpeak = "";
    if (typeof stringToParse === "string")
        stringToParse = ReactHtmlParser(stringToParse);
    for (let index = 0; index < stringToParse.length; index++) {
        if (typeof stringToParse[index] === 'string')
            stringToSpeak += stringToParse[index];
        // else if (stringToParse[index].type && !['table'].includes(stringToParse[index].type))
        // stringToSpeak += stringToSpeak.textContent;
    }

    return stringToSpeak || "  ";
}
