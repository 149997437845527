function checkForMobile() {
	return /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
function parseHtmlAndExtractText(htmlString) {
	let x = document.createElement("div")
	x.innerHTML = htmlString;
	return x.textContent;
}
function scrollIntoView(refEl) {
	if (refEl?.current)
		refEl.current.scrollIntoView({ behavior: "smooth", block: 'end' })
}
export { checkForMobile, parseHtmlAndExtractText, scrollIntoView };

