
function PageNotFound() {
    document.title = "Page not found"

    return (
        <div align="center" >
            <h1>Oops! Seem you are on wrong page</h1>
            <h2>Page Not Found</h2>
        </div>
    )
}

export default PageNotFound