// import { DoubleSide } from "three";
// import { useTexture } from "@react-three/drei";
// import imgBGWebp from "../images/bg.webp";

// export default function Comp() {
//   const texture = useTexture(imgBGWebp);
//   return (
//     //  position={[0, 0, -0.2]} rotation={[-Math.PI / 2, 0, 0]}
//     // <mesh position={[0, 1.6, -1.0]} scale={[0.215, 0.215, 0.6]}>
//     <mesh position={[0, 1.6, -1.0]} scale={[0.215, 0.215, 0.6]}>
//       <planeGeometry attach="geometry" args={[6, 5, 2]} />
//       <meshStandardMaterial attach="material" map={texture} side={DoubleSide} color="grey" />
//       <mesh position={[0, 0, -5]} />
//     </mesh>
//   );
// }


import React, { useState, useEffect } from 'react';
import { DoubleSide } from 'three';
import { useTexture } from '@react-three/drei';
// import imgBGWebp from "../images/background/bg.webp";
// import imgBGWebp2 from "../images/background/bg2.webp";

const organizationImages = {
  DENTAL_CLINIC : ['dentalclinic1.webp', 'dentalclinic2.webp', 'dentalclinic3.webp','dentalclinic4.webp','dentalclinic5.webp'],
  HOMECARE: ['homecare3.webp','homecare1.webp', 'homecare2.webp',  'homecare4.webp'],
  TECH_OFFICE: ['office3.webp','office1.webp', 'office2.webp','office4.webp'],
  RECEPTION: ['Reception.webp']
};

// Function to get the background image based on the organization and time of day
const getBackgroundImageBasedOnTimeOfDay = (organization) => {
  const now = new Date();
  const images = organizationImages[organization];
  let index=0;

  // Determine the time range and select the appropriate index
  // if (now.getHours() >= 9 && now.getHours() < 12) {
  //   index = 0; // 9am - 12pm
  // } else if (now.getHours() >= 12 && now.getHours() < 15) {
  //   index = 1; // 12pm - 3pm
  // } else if (now.getHours() >= 15 && now.getHours() < 18) {
  //   index = 2; // 3pm - 6pm
  // } else if (now.getHours() >= 18 && now.getHours() < 21) {
  //   index = 3; // 6pm - 9pm
  // } else {
  //   index = 4; 
  // }

  return `/background/${images[index]}`;
}


export default function Comp({ organization }) {
  // console.log(organization)
  const [backgroundImage, setBackgroundImage] = useState(getBackgroundImageBasedOnTimeOfDay(organization));

  useEffect(() => {
    const intervalId = setInterval(() => {
      const updatedBackgroundImage = getBackgroundImageBasedOnTimeOfDay(organization);
      setBackgroundImage(updatedBackgroundImage);
    }, 60 * 1000);

    return () => clearInterval(intervalId);
  }, [organization]);

  const texture = useTexture(backgroundImage);

  return (
    <mesh position={[0, 1.6, -1.0]} scale={[0.215, 0.215, 0.6]}>
      <planeGeometry attach="geometry" args={[6, 5, 2]} />
      <meshStandardMaterial attach="material" map={texture} side={DoubleSide} color="grey" />
      <mesh position={[0, 0, -5]} />
    </mesh>
  );
}