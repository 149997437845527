import { Fragment } from "react"

function FreshChatHints() {
    return (
        <Fragment>
            <h1 title="Will change it to something else">Enter query in area provided at bottom.</h1>
        </Fragment>
    )
}

export default FreshChatHints