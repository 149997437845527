import { createSlice } from "@reduxjs/toolkit";
var streamOverTimeout = null;
const companionChatSlice = createSlice({
    name: 'companionChat',
    initialState: {
        chatHistory: [],
        exampleQuestions: [],
        lastAPIWasStreamed: false,
        details: {
            model: {
                allowSpeak: true,
                gender: "F"
            },
            user: {
                companion_UserId: "",
                userId: "",
                name: ""
            },
            company: {
                orgId: "",
                companyName: "",
                tagLine: `Ask us anything!
                Ask us about yours health, wellness, appointments, insurance, our services`,
                imagUrl: "https://content.lab.safetylabs.org/photo/USER/7480/7480.jpeg",
                imageAltText: ""
            },
            chat: {

            },
            issUserAuthenticate: null
        },
        otherKey: "Their Values"
    },
    reducers: {
        ADD_EXAMPLE_QUESTIONS: (state, action) => {
            state.exampleQuestions = action.payload.questionsEg;
        },
        ADD_QUESTION: (state, action) => {
            state.chatHistory.push(getChatJSON(typeOfChatMessage.QUESTION, action.payload.question, new Date().getTime()))
        },
        ADD_ANSWER: (state, action) => {
            state.chatHistory.push(getChatJSON(typeOfChatMessage.ANSWER, action.payload.answer, new Date().getTime()))
            if (state.details.model.allowSpeak) {
                console.log(state.details.model);
            }
        },
        ADD_ANSWER_STREAMED: (state, action) => {
            if (state.chatHistory.at(-1).type !== typeOfChatMessage.ANSWER_STREAMED)
                state.chatHistory.push(getChatJSON(typeOfChatMessage.ANSWER_STREAMED, "", new Date().getTime()))
            if (typeof action.payload?.answer === 'string') {
                state.chatHistory.at(-1).text += action.payload.answer;
                state.chatHistory.at(-1)["endTimeStamp"] = new Date().getTime();
                clearTimeout(streamOverTimeout);
                // streamOverTimeout = setTimeout(() => {
                // console.log("Stream over");
                // this.SET_LAST_STREAMED_AS_FINISHED()
                // state.chatHistory.at(-1).isStreamFinished = true;
                // }, 2000);
            }
        },
        SET_LAST_STREAMED_AS_FINISHED: (state, action) => {
            if (state.chatHistory.at(-1).type === typeOfChatMessage.ANSWER_STREAMED)
                state.chatHistory.at(-1).isStreamFinished = true;
        },
        ADD_API_ERROR: (state, action) => {
            state.lastAPIWasStreamed = false;
            state.chatHistory.push(getChatJSON(typeOfChatMessage.API_ERROR, action.payload.errorMessage))
        },
        CLEAR_CHAT: (state, action) => {
            state.chatHistory.length = 0;
        },
        SET__USER_COMPANY_ID: (state, action) => {
            state.details.user.companion_UserId = action.payload;
        },
        SET_USER_AUTHENTICATED_STATUS: (state, action) => {
            state.details.issUserAuthenticate = action.payload;
        },
        TOGGLE_THIS_HIT_IS_STREAMED: (state, action) => {
            state.lastAPIWasStreamed = action.payload.answer;
        },

    }
});

function getChatJSON(chatType, textToAdd, timeStamp) {
    switch (chatType) {
        case typeOfChatMessage.QUESTION:
            return {
                type: typeOfChatMessage.QUESTION,
                receivedTimeStamp: timeStamp,
                text: textToAdd
            }
        case typeOfChatMessage.ANSWER:
            return {
                type: typeOfChatMessage.ANSWER,
                receivedTimeStamp: new Date().getTime(),
                endTimeStamp: timeStamp,
                text: textToAdd
            }
        case typeOfChatMessage.ANSWER_STREAMED:
            return {
                type: typeOfChatMessage.ANSWER_STREAMED,
                receivedTimeStamp: new Date().getTime(),
                endTimeStamp: timeStamp,
                text: textToAdd,
                isStreamFinished: false
            }
        case typeOfChatMessage.API_ERROR:
            return {
                type: typeOfChatMessage.API_ERROR,
                receivedTimeStamp: new Date().getTime(),
                text: textToAdd
            }
        default:
            return null;
    }
}

const typeOfChatMessage = {
    QUESTION: "QUESTION",
    API_ERROR: "API_ERROR",
    ANSWER: "ANSWER",
    ANSWER_STREAMED: "ANSWER_STREAMED"
}
Object.freeze(typeOfChatMessage);


export const { ADD_EXAMPLE_QUESTIONS, ADD_QUESTION, ADD_ANSWER, ADD_ANSWER_STREAMED, SET_LAST_STREAMED_AS_FINISHED, ADD_API_ERROR, SET__USER_COMPANY_ID,
    CLEAR_CHAT, SET_USER_AUTHENTICATED_STATUS, TOGGLE_THIS_HIT_IS_STREAMED } = companionChatSlice.actions;
export { typeOfChatMessage };
export default companionChatSlice.reducer;