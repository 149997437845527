import { useAnimations, useFBX, useGLTF, useTexture } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { TweenLite } from "gsap";
import { useEffect, useMemo, useState, useRef } from "react";
import { AnimationMixer, LineBasicMaterial, LinearSRGBColorSpace, LoopOnce, MeshPhysicalMaterial, MeshStandardMaterial, SRGBColorSpace, Vector2 } from "three";
import blinkData from "../modelsAndRelated/blendDataBlink.json";
import createAnimation from "../modelsAndRelated/converter";
import idleFbFile from "../modelsAndRelated/idle.fbx";
import avatarTextures from "../avatarUtlity/defaultTexture.json";
import avatarnewTextures from "../avatarUtlity/EvanewTexture.json"
var gltf;
var meshRef;
export function SetAvatarPosition() {
    TweenLite.to(gltf.scene.position, 0, { x: 0, y: 0.67, z: 0 });
    meshRef.current.scale.set(0.6, 0.6, 1);
}

export function SetAvatarPositionDefault() {
  TweenLite.to(gltf.scene.position, 0, { x: 0, y: 0, z: 0 });
  meshRef.current.scale.set(1, 1, 1);
}
export default function Avatar({ avatar_url, blendDataState, setShowModal,currentOrg }) {//avatarTexture
  document.title = "3d Avatar";
//console.log('currentOrg:'+currentOrg)
  gltf = useGLTF(avatar_url);
  
   meshRef = useRef();
 
  let morphTargetDictionaryBody = {};
  let morphTargetDictionaryLowerTeeth = {};
  let textureKeys;
  let texturePaths;
 // Load textures dynamically
//  if(currentOrg == 'org_3_helthcare_assistant'){//show new texture avatar
//   textureKeys = Object.keys(avatarnewTextures);//blue tshirt
//   texturePaths = textureKeys.map(key => avatarnewTextures[key]);
//  }else{
  textureKeys = Object.keys(avatarTextures);
  texturePaths = textureKeys.map(key => avatarTextures[key]);
//  }

/** replace texture folder path according to organisation  */
// useEffect(()=>{
//   texturePaths = texturePaths.map(path => {
//     //console.log(path)
//     debugger
//     if(currentOrg == 'org_3_helthcare_assistant'){
//       return path.replace("/texture/", "/texture2/");
//     }else{
//       return path.replace("/texture/", "/texture/");
//     }
   
//   });
// },[currentOrg])
 

    const [
    bodyTexture,
    eyesTexture,
    teethTexture,
    bodySpecularTexture,
    bodyRoughnessTexture,
    bodyNormalTexture,
    teethNormalTexture,
    hairTexture,
    tshirtDiffuseTexture,
    tshirtNormalTexture,
    tshirtRoughnessTexture,
    hairAlphaTexture,
    hairNormalTexture,
    hairRoughnessTexture,
  ] = useTexture(texturePaths);

  [
    bodyTexture,
    eyesTexture,
    teethTexture,
    teethNormalTexture,
    bodySpecularTexture,
    bodyRoughnessTexture,
    bodyNormalTexture,
    tshirtDiffuseTexture,
    tshirtNormalTexture,
    tshirtRoughnessTexture,
    hairAlphaTexture,
    hairNormalTexture,
    hairRoughnessTexture,
  ].forEach((t) => {
    t.colorSpace = SRGBColorSpace;
    t.flipY = false;
  });

  bodyNormalTexture.colorSpace = LinearSRGBColorSpace;
  tshirtNormalTexture.colorSpace = LinearSRGBColorSpace;
  teethNormalTexture.colorSpace = LinearSRGBColorSpace;
  hairNormalTexture.colorSpace = LinearSRGBColorSpace;

  gltf.scene.traverse((node) => {
    if (
      node.type === "Mesh" ||
      node.type === "LineSegments" ||
      node.type === "SkinnedMesh"
    ) {
      node.castShadow = true;
      node.receiveShadow = true;
      node.frustumCulled = false;
      if (node.name.includes("Body")) {
        node.castShadow = true;
        node.receiveShadow = true;

        node.material = new MeshPhysicalMaterial();
        node.material.map = bodyTexture;
        // node.material.shininess = 60;
        node.material.roughness = 1.7;

        // node.material.specularMap = bodySpecularTexture;
        node.material.roughnessMap = bodyRoughnessTexture;
        node.material.normalMap = bodyNormalTexture;
        node.material.normalScale = new Vector2(0.6, 0.6);
        morphTargetDictionaryBody = node.morphTargetDictionary;
        node.material.envMapIntensity = 0.8;
        // node.material.visible = false;
      }

      if (node.name.includes("Eyes")) {
        node.material = new MeshStandardMaterial();
        node.material.map = eyesTexture;
        // node.material.shininess = 100;
        node.material.roughness = 0.1;
        node.material.envMapIntensity = 0.5;
      }

      if (node.name.includes("Brows")) {
        node.material = new LineBasicMaterial({ color: 0x000000 });
        node.material.linewidth = 1;
        node.material.opacity = 0.5;
        node.material.transparent = true;
        node.visible = false;
      }

      if (node.name.includes("Teeth")) {
        node.receiveShadow = true;
        node.castShadow = true;
        node.material = new MeshStandardMaterial();
        node.material.roughness = 0.1;
        node.material.map = teethTexture;
        node.material.normalMap = teethNormalTexture;

        node.material.envMapIntensity = 0.7;
      }

      if (node.name.includes("Hair")) {
        node.material = new MeshStandardMaterial();
        node.material.map = hairTexture;
        node.material.alphaMap = hairAlphaTexture;
        node.material.normalMap = hairNormalTexture;
        node.material.roughnessMap = hairRoughnessTexture;

        node.material.transparent = true;
        node.material.depthWrite = false;
        node.material.side = 2;
        node.material.color.setHex(0x000000);
        node.material.envMapIntensity = 0.3;
        node.material.opacity = 4;
      }

      if (node.name.includes("TSHIRT")) {
        node.material = new MeshStandardMaterial();
        node.material.map = tshirtDiffuseTexture;
        node.material.roughnessMap = tshirtRoughnessTexture;
        node.material.normalMap = tshirtNormalTexture;
        node.material.color.setHex(0xffffff);
        node.material.envMapIntensity = 0.5;
      }

      if (node.name.includes("TeethLower")) {
        morphTargetDictionaryLowerTeeth = node.morphTargetDictionary;
      }
    }
  });

  // Load idle body animations
  const [clips, setClips] = useState([]);
  const mixer = useMemo(() => new AnimationMixer(gltf.scene), [gltf.scene]);
  let idleFbx = useFBX(idleFbFile);
  let { clips: idleClips } = useAnimations(idleFbx.animations); // Variable is idleClips from clips state of useAnimations // from .fbx
  idleClips[0].tracks = idleClips[0].tracks.filter(track => track.name.includes("Head") || track.name.includes("Neck") || track.name.includes("Spine2"));
  idleClips[0].tracks = idleClips[0].tracks.map(track => {
    if (track.name.includes("Head")) track.name = "head.quaternion";
    if (track.name.includes("Neck")) track.name = "neck.quaternion";
    if (track.name.includes("Spine")) track.name = "spine2.quaternion";
    return track;
  });

  const [animationReady, setAnimationReady] = useState(null);

  useEffect(() => {
    if (blendDataState?.length > 0) {
      let newClips = [
        createAnimation(blendDataState, morphTargetDictionaryBody, "HG_Body"),
        createAnimation(
          blendDataState,
          morphTargetDictionaryLowerTeeth,
          "HG_TeethLower"
        ),
      ];

      let stopTime = Math.round(newClips[0].duration * 1000 * 1);
      setClips(newClips);
      setAnimationReady(true);

      setTimeout(() => {
        setAnimationReady(false);
      }, stopTime);
    }
  }, [blendDataState]);

  //
  const [playing, setPlaying] = useState(false);
  useEffect(() => {
    // Will have to false animation Ready
    if (animationReady === true) {
      setPlaying(true);
    } else {
      setPlaying(false);
    }
  }, [animationReady]);

  useEffect(() => {
    if (playing === true)
      clips.forEach((clip) => {
        let clipAction = mixer.clipAction(clip);
        clipAction.setLoop(LoopOnce);
        clipAction.play();
      });
  }, [playing]);

  //	Adding Idle body movement and Eye blink
  useEffect(() => {
    let idleBody = mixer.clipAction(idleClips[0]);
    idleBody.play();
    let idleBlink = createAnimation(
      blinkData,
      morphTargetDictionaryBody,
      "HG_Body"
    );
    let blinkAction = mixer.clipAction(idleBlink);
    
    if (blinkAction != null) {
    blinkAction.play();
    }
    //console.log("MODEL LOADED BLINK")
    setShowModal(true);
  }, []);

  useFrame((state, delta) => {
    mixer.update(delta);
  });

  return <primitive object={gltf.scene} dispose={null} ref={meshRef}/>;
}
