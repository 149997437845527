import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import './Question.css'
export default function Question({ onQuestionClick, exampleQues , quesTionClass}) {
   
    let questions=exampleQues;
    const handleQuestionClick = (question) => {
        // Call the callback function with the selected question
        onQuestionClick(question);
    };

    return (
        <>
        {questions.length > 0 && (
            <div className={`main-container ${quesTionClass}`}>
                {questions.map((category, index) => (
                    <div key={index}>
                        <h2 className='questionTitle'>{category.heading}</h2>
                        <ul className="exampleListContainer">
                            {category.questions.map((question, qIndex) => (
                                <li key={qIndex} onClick={() => handleQuestionClick(question)}>
                                    {question}
                                    <i><FontAwesomeIcon icon={faPaperPlane} /></i>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        )} 
      </>
    );
}
