import apiEndPoints from './apiEndPoints';
import { ADD_ANSWER, ADD_ANSWER_STREAMED, ADD_API_ERROR, ADD_EXAMPLE_QUESTIONS, ADD_QUESTION, CLEAR_CHAT, SET_LAST_STREAMED_AS_FINISHED, SET_USER_AUTHENTICATED_STATUS, SET__USER_COMPANY_ID, TOGGLE_THIS_HIT_IS_STREAMED } from './reduxReducers/CompanionChatSlice';

const RESOLVE_QUERY = (json) => (dispatch) => {
	dispatch(ADD_QUESTION({ question: json.query }));
	// dispatch(ADD_ANSWER({ answer: `Response**dfsd v     v**Response Response` }));
	apiEndPoints.COMPANION_CHAT().resolveQuery({ lastQueryResponse: json.lastQuestion, query: json.query })
		.then(async resp => {
			dispatch(TOGGLE_THIS_HIT_IS_STREAMED({ answer: false }))
			if (resp.status === 200) {
				console.log("Server response:", resp);
				if (resp?.data?.AIGS_MSG_TYPE === "STREAM_LLM_JSON") {
					console.log(resp.data, resp.data.AIGS_MSG_TYPE);
					// console.log("headers: ", resp.headers, " | Header AIGS_MSG_TYPE: ", resp.headers.get("AIGS_MSG_TYPE"));

					dispatch(TOGGLE_THIS_HIT_IS_STREAMED({ answer: true }))
					fetch(resp.data.LLM_CONFIG.BASE_URL, {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							"Authorization": `Bearer ${resp.data.LLM_CONFIG.API_KEY || resp.data.LLM_CONFIG.KEY}`,
						},
						body: JSON.stringify({
							model: resp.data.LLM_CONFIG.MODEL_NAME || resp.data.LLM_CONFIG.MODEL,
							messages: [{
								role: "user",
								content: resp.data.QUERY_WITH_PROMPT
							}],
							temperature: resp.data.LLM_CONFIG.TEMPERATURE,
							stream: true
						})
					})
						.then((response) => {
							const reader = response.body.getReader();
							return new ReadableStream({
								start(controller) {
									return pump();
									function pump() {
										return reader.read()
											.then(({ done, value }) => {
												controller.enqueue(value);
												let chars = new TextDecoder().decode(value);
												let chunkData = chars.split('data:');
												let chunkTexts = "";
												chunkData.forEach(eachLine => {
													let chunkChars = eachLine.replace(/\s* /, '').replace(/\s*$/, '');
													if (chunkChars) {
														if (chunkChars.indexOf("DONE") > -1 || done) {
															console.log("Done received", done, value);
															dispatch(SET_LAST_STREAMED_AS_FINISHED({}))
															controller.close();
															return;
														}
														try {
															let obj = JSON.parse(chunkChars);
															if (obj && obj.choices[0].delta.content)
																chunkTexts += obj.choices[0].delta.content
														} catch (error) {
															console.error(error.message);
														}
													} //else console.warn("No data in chunk", eachLine);
												});
												// console.log({ chunkTexts });
												dispatch(ADD_ANSWER_STREAMED({ answer: chunkTexts }))
												return pump();
											});
									}


								},
							});
						})
						.catch((err) => {
							console.log("message ", err.message)
						});
				} else
					dispatch(ADD_ANSWER({ answer: resp.data.trim() }));
			}
		})
		.catch(err => {
			dispatch(ADD_API_ERROR({ errorMessage: `${err.message}, code: ${err.code}` }))
			return err.code;
		});
}
const CONVERSATION_ENDED = () => (dispatch) => {
	apiEndPoints.CHAT_ACTION().conversationEnded()
		.then(res => {
			if (res.status === 200) {
				dispatch(CLEAR_CHAT());
				setTimeout(() => {
					dispatch(ADD_AI_INITIAL_MESSAGE())
				}, 10);
			}
		})
		.catch(err => {
			dispatch(ADD_API_ERROR({ errorMessage: `${err.message}, code: ${err.code}` }))
			return err.code;
		})
}
const ADD_AI_INITIAL_MESSAGE = () => (dispatch) => {
	// dispatch(ADD_ANSWER({ answer: apiEndPoints.COMPANION_CHAT().initialAIMessage() }));	// Client side static message
	// OR
	apiEndPoints.CHAT_ACTION().initialAIMessage()
		.then(resp => {
			console.log(resp, resp.data);
			if (resp.status === 200) {
				dispatch(ADD_ANSWER({ answer: resp.data.GREETING }));
				dispatch(ADD_EXAMPLE_QUESTIONS({ questionsEg: resp?.data?.EXAMPLE_QUESTIONS || [] }));
			} else
				dispatch(ADD_ANSWER({ answer: "Hello" }));
		}).catch()
}
const SET_USER_COMPANY_ID = (companion_UserId) => (dispatch) => {
	dispatch(SET__USER_COMPANY_ID(companion_UserId))
}
const SET_USER_COMPANY_DETAILS = (companion_UserId) => (dispatch) => {
	apiEndPoints.USER_COMPANY_DETAILS().getUserCompanyDetails(companion_UserId)
		.then(resp => {
			console.warn(resp);
			console.warn(resp.data);

			let userFound = true
			if (userFound) {
				// Set issUserAuthenticate = true, user and company details in the global states...
				dispatch(SET_USER_AUTHENTICATED_STATUS(true))
			} else {
				dispatch(SET_USER_AUTHENTICATED_STATUS(false))
				// Set issUserAuthenticate = false
			}
		})
		.catch((err) => {
			// Redirect the application to the Home/Error page
			dispatch(SET_USER_AUTHENTICATED_STATUS(false))
			dispatch(ADD_API_ERROR({ errorMessage: `${err.message}, code: ${err.code}` }))
			return err.code;
		})
}

export {
	ADD_AI_INITIAL_MESSAGE, CONVERSATION_ENDED, RESOLVE_QUERY,
	SET_USER_COMPANY_DETAILS, SET_USER_COMPANY_ID
};