import { useState } from 'react';
import { LuCheckCircle, LuClipboardCopy, LuTimer } from 'react-icons/lu';
import sheet from './Answer.module.css';
import TextToSpeechComp from './textToSpeech/TextToSpeechComp';
import { typeOfChatMessage } from '../../actions/reduxReducers/CompanionChatSlice';

function Answer({ chat, Lqt, chatIndex, lastIndex }) {
    const [allowTextCopy, setAllowTextCopy] = useState(true);
    // if (chat.type === typeOfChatMessage.ANSWER_STREAMED)  console.log(chat);
    function copyContent(text) {
        navigator.clipboard.writeText(text);
        setAllowTextCopy(false);
        setTimeout(() => {
            setAllowTextCopy(true);
        }, 2000);
    }
    return (
        <div className={[sheet.serverResponse, sheet.regular].join(" ")}>
            <div className={sheet.oneNewSectionForHeadAndRead}>
                <div className={sheet.contentToReadLoud} dangerouslySetInnerHTML={{ __html: processText(chat.text) }}></div>
                <div className={sheet.responseHead} aria-hidden={true}>
                    {Lqt && chatIndex !== 0 ?
                        <div className={sheet.timing} >
                            <LuTimer />
                            {msToTime(chat.receivedTimeStamp - Lqt)}
                        </div> : null}
                    {/* {console.log(chatIndex === lastIndex && (chat.isStreamFinished === true || chat.type === typeOfChatMessage.ANSWER), chatIndex, lastIndex, chat.isStreamFinished, chat.type === typeOfChatMessage.ANSWER)} */}
                    <TextToSpeechComp elementAsString={processText(chat.text)} highlightText={true} autoPlay_={(chatIndex === lastIndex && (chat.isStreamFinished === true || chat.type === typeOfChatMessage.ANSWER))} />
                    {allowTextCopy ?
                        <LuClipboardCopy className={sheet.copyContent} onClick={() => copyContent(processText(chat.text))} /> :
                        <LuCheckCircle className={sheet.contentCopied} onClick={() => setAllowTextCopy(true)} />}
                </div>
            </div>
        </div>
    )
}

function processText(respData) {
    let patterns = [
        "assistant",
        "assistant:",
        "assistant :",
        "output",
        "output:",
        "output :",
        "<>",
        "< >",
        "<<>>",
        "<< >>",
        "<<sys>>",
        "Response",
        "Response:",
        "Response :",
        "<</sys>>"
    ];
    let regex = new RegExp("^(" + patterns.map(pattern => pattern.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join("|") + ")", 'i');
    return removeAllX(respData.toLowerCase().replace(regex, ""));
}
function removeAllX(text) {
    const patterns = [
        "**"
    ];
    const regex = new RegExp(patterns.map(pattern => pattern.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join("|"), 'gi');
    return text.toLowerCase().replace(regex, "");
}
function msToTime(duration) {
    var milliseconds = parseInt((duration % 1000) / 100),
        seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    if (hours > 0)
        return hours + ":" + minutes + ":" + seconds + "." + milliseconds + "hour";
    else if (minutes > 0)
        return minutes + ":" + seconds + "." + milliseconds + "min";
    else if (seconds > 0)
        return seconds + "." + milliseconds + "s";
    else
        return milliseconds + "ms";
}

export default Answer